<script lang="ts">
  import Button from '$lib/components/button/button.svelte';
  import Text from '$lib/components/text/text.svelte';
  import type { PageHero } from '$lib/contentful/models/page-hero';
  import Grid from '$lib/layout/grid/grid.svelte';
  import Box from '$lib/layout/grid/box.svelte';
  import PageNavigation from '../page-navigation/page-navigation.svelte';
  import RichText from '../rich-text/rich-text.svelte';
  import ContentfulImage from '../contentful-image/contentful-image.svelte';
  import MarketoForm from '$lib/components/marketo-form/marketo-form.svelte';

  export let content: PageHero | undefined = undefined;
  export let home: boolean = false;
</script>

{#if content}
  {@const {
    eyebrow,
    breadcrumbText,
    breadcrumbHref,
    heading,
    body,
    links,
    callsToAction,
    entityId,
    background,
    alignment,
    image,
    marketoForm,
  } = content}
  {@const span = home ? 5 : 10}
  <Grid layout="rows" as="div" {background}>
    <Grid layout="columns" as="div" class="flex items-center">
      <Box
        as="div"
        {span}
        class="max-md:pt-10 md:py-8 xl:py-24 flex flex-row items-center gap-8 {alignment ===
        'center'
          ? 'justify-center'
          : 'justify-start'}"
      >
        <div class="hero-content {alignment}" class:home>
          {#if eyebrow}
            <div class="flex gap-2">
              <Text
                as="h4"
                variant="eyebrow-16"
                class="z-10"
                {background}
                {entityId}
                fieldId="eyebrow"
              >
                <slot name="eyebrow">
                  {#if breadcrumbHref}
                    <a href={breadcrumbHref}>{eyebrow}</a>
                  {:else}
                    {eyebrow}
                  {/if}
                </slot>
                {#if breadcrumbText}
                  &gt;
                  <span>
                    {breadcrumbText}
                  </span>
                {/if}
              </Text>
            </div>
          {/if}
          {#if image && alignment === 'center'}
            <ContentfulImage
              src={image.url}
              alt={image.description}
              fieldId="image"
              {entityId}
            />
          {/if}
          {#if heading}
            <Text
              as="h1"
              variant={home ? 'display-68' : 'display-104'}
              class="z-10"
              {background}
              {entityId}
              fieldId="heading"
            >
              <slot name="heading">{heading}</slot>
            </Text>
          {/if}
          {#if image && alignment === 'left'}
            <ContentfulImage
              pictureClass="sm:hidden"
              src={image.url}
              alt={image.description}
              fieldId="image"
              {entityId}
            />
          {/if}
          {#if body || $$slots.body}
            <slot name="body">
              <RichText
                {background}
                fieldId="body"
                {entityId}
                text={body}
                class="z-10"
              />
            </slot>
          {/if}
          {#if marketoForm}
            <MarketoForm
              class="max-lg:w-full max-xl:w-2/3 xl:w-1/2"
              content={marketoForm}
            />
          {/if}
          <div class="hero-cta {alignment}">
            <slot name="ctas">
              {#each callsToAction as { text, ...rest }}
                <Button {...rest}>{text}</Button>
              {/each}
            </slot>
          </div>
        </div>
        {#if image && alignment === 'left'}
          <ContentfulImage
            pictureClass="col-span-3 col-start-9 max-sm:hidden"
            src={image.url}
            alt={image.description}
            fieldId="image"
            {entityId}
          />
        {/if}
      </Box>
      {#if $$slots['home-animation']}
        <Box as="div" offset={9} span={6} class="h-full py-16">
          <slot name="home-animation" />
        </Box>
      {/if}
    </Grid>
    {#if links}
      <PageNavigation {links} />
    {/if}
  </Grid>
{/if}

<style lang="postcss">
  .hero-content {
    @apply flex flex-col sm:gap-8 max-sm:gap-2;

    &:not(.home) {
      @apply xl:max-w-[75%] 2xl:max-w-[66%];
    }

    &.left {
      @apply items-start;
    }

    &.center {
      @apply items-center w-full text-center;
    }
  }

  .hero-cta {
    @apply flex flex-row items-center gap-4 flex-wrap;

    &.center {
      @apply justify-center;
    }
  }
</style>
